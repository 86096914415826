import { makeQuery } from "../api";
import { ApiEndpoints } from "../endpoints";
import { LeaderboardsSchema } from "../../model/leaderboards/schema";
import { FilterParameter, RangeParameter } from "../types";

interface GetLoaderboardsProps {
  shooterId?: number;
  range: RangeParameter;
  filter?: FilterParameter;
}

export default async function getLeaderboards({
  range,
  shooterId,
  filter,
}: GetLoaderboardsProps) {
  const query = shooterId ? `shooterId=${shooterId}` : undefined;
  const modifiedFilter = filter
    ? filter.filter(({ column }) => column !== "display")
    : undefined;
  try {
    const response = await makeQuery(
      ApiEndpoints.leaderboards(),
      false,
      undefined,
      range,
      modifiedFilter,
      query
    );
    return LeaderboardsSchema.parse(response);
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
