import React from "react";
import styled from "@emotion/styled";
import styleVariables from "../../../helpers/styleVariables";
import NextLink from "next/link";

interface LinkProps {
  href?: string;
  onClick?: () => void;
  isBold?: boolean;
  disabled?: boolean;
}

type LinkStylesProps = Pick<LinkProps, "isBold" | "disabled">;

const Root = styled.span<LinkStylesProps>`
  > * {
    color: ${(props) =>
      props.disabled ? "unset" : styleVariables.colors.blueRegular} !important;
    text-decoration: ${(props) => (props.disabled ? "none" : "underline")};
    font-weight: ${(props) => (props.isBold ? "bold" : "normal")};
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
`;

const StyledLink = styled.a<LinkStylesProps>``;

const Link: React.FC<LinkProps> = ({
  disabled,
  href,
  children,
  onClick,
  isBold,
}) => {
  return (
    <Root isBold={isBold} disabled={disabled}>
      {!disabled && !onClick ? (
        <StyledLink as={NextLink} href={href || ""} onClick={onClick}>
          {children}
        </StyledLink>
      ) : (
        <StyledLink as={"span"} onClick={disabled ? undefined : onClick}>
          {children}
        </StyledLink>
      )}
    </Root>
  );
};

export default Link;
