import {
  FilterParameter,
  QueryHeader,
  QueryParameter,
  SortParameter,
} from "./types";
import {
  ApiMutationResponseSchema,
  ApiQueryResponseSchema,
} from "../network/apiResponseSchema";
import ResponseFormatError from "./errors/responseFormatError";
import StandardApiError from "./errors/standardApiError";
import { RangeParameter } from "./types";

const getQueryResponse = async (
  url: string,
  withAuth: boolean = false,
  sort: SortParameter = undefined,
  filter: FilterParameter = undefined,
  query: QueryParameter = undefined,
  requestHeaders: QueryHeader = {}
) => {
  const queryParams = [];
  if (sort) {
    queryParams.push(
      `orderBy=${encodeURIComponent(
        sort.map((item) => `${item.column}[${item.direction}]`).join(",")
      )}`
    );
  }
  if (filter && filter.length) {
    queryParams.push(
      `filterBy=${encodeURIComponent(
        filter
          .map((item) => {
            if (Array.isArray(item.value)) {
              return item.value
                .map(
                  (subValue) => `(${item.column}[${item.operator}]${subValue})`
                )
                .join("or");
            }
            return item.operator === "like"
              ? `(${item.column}[${item.operator}]%${item.value}%)`
              : `(${item.column}[${item.operator}]${item.value})`;
          })
          .join("and")
      )}`
    );
  }
  if (query) {
    queryParams.push(query);
  }
  return await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}${url}${
      queryParams ? `?${queryParams.join("&")}` : ""
    }`,
    {
      mode: "cors",
      headers: requestHeaders,
    }
  );
};

export const makeQuery = async (
  url: string,
  withAuth: boolean = false,
  sort: SortParameter = undefined,
  range: RangeParameter = undefined,
  filter: FilterParameter = undefined,
  query: QueryParameter = undefined
) => {
  const headers: any = {};
  if (range) {
    headers.Range = `items=${range.from}-${range.to}`;
  }
  const res = await getQueryResponse(
    url,
    withAuth,
    sort,
    filter,
    query,
    headers
  );
  const json = await res.json();
  const parsedResponse = ApiQueryResponseSchema.safeParse(json);
  if (parsedResponse.success === false) {
    throw new ResponseFormatError(parsedResponse, res);
  } else {
    if (parsedResponse.success && parsedResponse.data.success) {
      return parsedResponse.data;
    } else {
      throw new StandardApiError(parsedResponse.data, res);
    }
  }
};

export const makeMutation = async (
  url: string,
  values?: unknown,
  withAuth: boolean = false,
  method: string = "POST"
) => {
  const headers: Record<string, string> = {
    "Content-Type": "application/json",
  };
  const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}${url}`, {
    method,
    headers,
    body: values ? JSON.stringify(values) : undefined,
  });
  const json = await res.json();
  const parsedResponse = ApiMutationResponseSchema.safeParse(json);
  if (parsedResponse.success === false) {
    throw new ResponseFormatError(parsedResponse, res);
  } else {
    if (parsedResponse.success && parsedResponse.data.success) {
      return parsedResponse.data;
    } else {
      throw new StandardApiError(parsedResponse.data, res);
    }
  }
};
