import * as z from "zod";

export const CountrySchema = z.object({
  id: z.number(),
  code: z.string(),
  code3: z.string(),
});

export const ClubSchema = z.object({
  id: z.number(),
  name: z.string(),
});

export const AchievementSchema = z.object({
    id: z.number(),
    rank: z.number(),
    country: CountrySchema,
    championship: z.string(),
    discipline: z.string(),
    totalScore: z.number(),
    avgScore: z.number(),
});

export const ProfileSchema = z.object({
  id: z.number(),
  displayName: z.string(),
  age: z.number(),
  avatar: z.string().nullable(),
  country: CountrySchema,
  club: ClubSchema.nullable(),
  category: z.string(),
  discipline: z.array(z.string()),
  achievements: z.array(AchievementSchema),
});

export type Achievement = z.infer<typeof AchievementSchema>;
export type Club = z.infer<typeof ClubSchema>;
export type Profile = z.infer<typeof ProfileSchema>;
export type Country = z.infer<typeof CountrySchema>;
