import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "next-i18next";
import styleVariables from "../../../helpers/styleVariables";
import breakpoints from "../../../helpers/breakpoints";

export enum Heading1Type {
  alternative = "alternative",
  greyed = "greyed",
}

interface Heading1Props {
  type?: Heading1Type;
  large?: boolean;
  as?: React.ElementType;
  className?: string;
}

interface Heading1StylesProps extends Pick<Heading1Props, "type" | "large"> {
  styleType?: Heading1Type;
}

const Root = styled.div<Heading1StylesProps>`
  font-size: ${styleVariables.fontSize.heading1FontSize};
  color: ${(props) => {
    if (props.styleType === Heading1Type.alternative) {
      return styleVariables.colors.blueDark;
    } else if (props.styleType === Heading1Type.greyed) {
      return styleVariables.colors.grey;
    } else {
      return styleVariables.colors.blueRegular;
    }
  }};
  font-weight: normal;
  line-height: 1.2;
  @media (min-width: ${breakpoints.min.lg}) {
    font-size: ${(props) =>
      props.large
        ? styleVariables.fontSize.heading1LargeFontSize
        : styleVariables.fontSize.heading1FontSize};
    line-height: ${(props) => (props.large ? 1.07 : 1.2)};
  } ;
`;

const Heading1: React.FC<Heading1Props> = ({
  className,
  as,
  type,
  large,
  children,
}) => {
  return (
    <Root as={as} styleType={type} large={large} className={className}>
      {children}
    </Root>
  );
};

export default Heading1;
