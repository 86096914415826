import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "next-i18next";
import styleVariables from "../../../helpers/styleVariables";
import breakpoints from "../../../helpers/breakpoints";

const Root = styled.div`
  font-size: ${styleVariables.fontSize.heading3FontSize};
  @media (min-width: ${breakpoints.min.lg}) {
    font-size: ${styleVariables.fontSize.heading2FontSize};
  }
  color: ${styleVariables.colors.blueRegular};
  font-weight: normal;
  line-height: 1.1875;
`;

const Heading2: React.FC = ({ children }) => {
  return <Root>{children}</Root>;
};

export default Heading2;
