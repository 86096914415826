export default class StandardApiError extends Error {
  public originalError: unknown;
  public response: Response;

  constructor(originalError: unknown, response: Response) {
    super();
    this.originalError = originalError;
    this.response = response;
  }

  public toString() {
    console.error(this.originalError);
    console.error(this.response);
    return `StandardApiError ${this.message} ${this.response}`;
  }
}
