import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "next-i18next";
import ReactWorldFlags from "react-world-flags";
import Link from "../typo/link/link";
import { Box } from "@mui/material";

export enum FlagSize {
  large = "large",
  small = "small",
}

interface FlagProps {
  size: FlagSize;
  code: string;
  text?: string;
  onClick?: () => void;
  disabled?: boolean;
}

type FlagStylesProps = Pick<FlagProps, "size">;

const FlagOuter = styled.span<FlagStylesProps>`
  width: ${(props) => (props.size === FlagSize.large ? "32px" : "16px")};
  height: ${(props) => (props.size === FlagSize.large ? "32px" : "16px")};
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;

  & img {
    height: 100%;
    width: auto;
  }
`;

const Flag: React.FC<FlagProps> = ({ size, code, text, onClick, disabled }) => {
  const { t } = useTranslation();

  return (
    <Link
      isBold={true}
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
    >
      <Box display="flex" alignItems="center">
        <FlagOuter size={size}>
          <ReactWorldFlags code={code} />
        </FlagOuter>
        {text && <Box pl={1.5}>{text}</Box>}
      </Box>
    </Link>
  );
};

export default Flag;
