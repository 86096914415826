import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "next-i18next";
import { Grid, Skeleton } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import styleVariables from "../../helpers/styleVariables";
import { useQuery } from "react-query";
import queryKeys from "../../api/queryKeys";
import getLeaderboards from "../../api/queries/getLeaderboards";
import { LeaderboardsItem } from "../../model/leaderboards/schema";
import { FilterGetParams } from "../../../pages/leaderboards";
import { FilterOperator } from "../../api/types";
import Flag, { FlagSize } from "../flag/flag";
import breakpoints from "../../helpers/breakpoints";
import useChangeFilterInstantly from "../../hooks/useChangeFilterInstantly";
import Link from "../../components/typo/link/link";
import Paragraph from "../typo/paragraph/paragraph";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useRouter } from "next/router";
import { Box } from "@mui/system";
import Image from "next/image";

const Root = styled.div`
  text-align: center;
  margin-top: 8px;
  margin-bottom: 35px;
`;

const Name = styled.div`
  font-weight: normal;
  font-size: 16px;
  font-weight: normal;
  display: flex;
  line-height: 1.25;
  margin: 4px 0;
  justify-content: center;
  @media (min-width: ${breakpoints.min.md}) {
    font-size: 32px;
    font-weight: bold;
  }
`;

const Score = styled.div`
  color: ${styleVariables.colors.grey};
  font-size: 24px;
  letter-spacing: -0.4px;
  margin-top: auto;
  display: flex;
  justify-content: center;
  @media (min-width: ${breakpoints.min.md}) {
    font-size: 32px;
  }
`;

const Info = styled.div`
  font-size: 16px;
  display: none;
  justify-content: center;

  @media (min-width: ${breakpoints.min.md}) {
    display: flex;
    align-items: center;
  }
`;

const Separator = styled.div`
  margin: 0 12px;
`;

const AvatarCover = styled.div`
  display: inline-flex;
  justify-content: center;
  position: relative;
`;

const Inner = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

const Medal = styled.div`
  position: absolute;
  right: 0;
  bottom: 8px;
  transform: translate(25%, 25%);

  img {
    display: block;
  }
`;

enum MedalType {
  Gold = "gold",
  Silver = "silver",
  Bronze = "bronze",
}

interface PodiumItem {
  medal: MedalType;
  data: LeaderboardsItem | undefined;
  isLarge?: boolean;
  query: FilterGetParams;
  isLoading: boolean;
}

const PodiumItem: React.FC<PodiumItem> = ({
  medal,
  isLarge,
  data,
  query,
  isLoading,
}) => {
  const router = useRouter();
  const { handleSubmitFilterInstantChange } = useChangeFilterInstantly(query);
  const isMdUp = useMediaQuery(`(min-width: ${breakpoints.min.md})`);
  const avatarSize = isMdUp ? (isLarge ? 192 : 96) : isLarge ? 96 : 60;
  const diffBetweenSizes = isMdUp ? (192 - 96) / 8 : (96 - 60) / 8;
  const getMedalIconName = () => {
    if (medal === MedalType.Gold) {
      return "gold";
    } else if (medal === MedalType.Silver) {
      return "silver";
    } else {
      return "bronze";
    }
  };
  const disabled = Boolean(query.id);
  const isHome = router.pathname === "/";
  return (
    <Inner>
      <Box display="flex" justifyContent="center">
        <AvatarCover>
          {data && !isLoading ? (
            <Box
              sx={{
                marginTop: !isLarge ? diffBetweenSizes : 0,
              }}
            >
              {data.user.avatar === null ? (
                <Avatar
                  sx={{
                    width: avatarSize,
                    height: avatarSize,
                  }}
                  alt=""
                />
              ) : (
                <Image
                  src={data.user.avatar}
                  className="rounded-circle"
                  alt=""
                  width={avatarSize}
                  height={avatarSize}
                  objectFit="cover"
                />
              )}
            </Box>
          ) : (
            <Skeleton
              variant="circular"
              width={avatarSize}
              height={avatarSize}
              sx={{ marginTop: !isLarge ? diffBetweenSizes : 0 }}
            />
          )}
          <Medal>
            <img
              src={`/images/medals/${getMedalIconName()}.svg`}
              width={32}
              height={32}
              className="medal"
              alt=""
            />
          </Medal>
        </AvatarCover>
      </Box>
      <Name>
        {data && !isLoading ? (
          <Link
            href={`/shooter/${data.user.id}?discipline=${query.discipline}`}
          >
            {data.user.displayName}
          </Link>
        ) : (
          <Skeleton variant="text" width={80} />
        )}
      </Name>
      <Info>
        {data && !isLoading ? (
          <>
            <Flag
              size={FlagSize.large}
              code={data.country.code}
              disabled={disabled}
              text={data.country.code3}
              onClick={() =>
                isHome
                  ? router.push(
                      `/leaderboards?discipline=${query.discipline}&country=${data.country.id}`
                    )
                  : handleSubmitFilterInstantChange("country", data.country.id)
              }
            />
            <Separator>/</Separator>{" "}
            {data.club?.id ? (
              <Link
                onClick={() =>
                  isHome
                    ? router.push(
                        // @ts-ignore data.club is possibly 'null'
                        `/leaderboards?discipline=${query.discipline}&club=${data.club.id}`
                      )
                    : // @ts-ignore data.club is possibly 'null'
                      handleSubmitFilterInstantChange("club", data.club.id)
                }
                disabled={disabled}
                isBold={true}
              >
                {data.club.name}
              </Link>
            ) : (
              "-"
            )}
          </>
        ) : (
          <Skeleton variant="text" width={120} height={32} />
        )}
      </Info>
      <Score>
        {data && !isLoading ? (
          <>
            {query.display === "averageScore"
              ? data.avgScore.toFixed(3)
              : data.totalScore.toFixed(data.discipline === '10m_air_pistol' ? 0 : 1)}
          </>
        ) : (
          <Skeleton variant="text" width={80} />
        )}
      </Score>
    </Inner>
  );
};

interface PodiumProps {
  query: FilterGetParams;
  hideImage?: boolean;
}

export const renameFilterColumns = (input: string) => {
  if (input === "club") {
    return "club.id";
  }
  if (input === "country") {
    return "country.id";
  }
  return input;
};

const Podium: React.FC<PodiumProps> = ({ query, hideImage }) => {
  const { t } = useTranslation();
  const filter = Object.entries(query)
    .filter((item) => item[0] !== "id")
    .map((item) => ({
      column: renameFilterColumns(item[0]),
      value: item[1],
      operator: "eq" as FilterOperator,
    }));
  const range = { from: 0, to: 2 };
  const { data, isLoading } = useQuery(
    queryKeys.leaderboards(range, filter),
    () =>
      getLeaderboards({
        range,
        filter,
      })
  );

  if ((data && !data.data) || (data && data.data.length === 0)) {
    return <Paragraph>{t("noDataAvailable")}</Paragraph>;
  }

  return (
    <Root>
      <Grid container>
        <Grid item xs={0} md={1}></Grid>
        <Grid item xs={4} md={3}>
          <PodiumItem
            medal={MedalType.Silver}
            isLoading={isLoading}
            data={data && data.data.find(({ rank }) => rank === 2)}
            query={query}
          />
        </Grid>
        <Grid item xs={4}>
          <PodiumItem
            medal={MedalType.Gold}
            isLoading={isLoading}
            isLarge={true}
            data={data && data.data.find(({ rank }) => rank === 1)}
            query={query}
          />
        </Grid>
        <Grid item xs={4} md={3}>
          <PodiumItem
            isLoading={isLoading}
            medal={MedalType.Bronze}
            data={data && data.data.find(({ rank }) => rank === 3)}
            query={query}
          />
        </Grid>
        <Grid item xs={0} md={1}></Grid>
        {!hideImage && (
          <Grid item xs={12}>
            <img
              src="/images/podium.png"
              alt=""
              className="img-fluid"
              width={1108}
              height={111}
            />
          </Grid>
        )}
      </Grid>
    </Root>
  );
};

export default Podium;
