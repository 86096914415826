import * as z from "zod";

const ResponseDataSchema = z.union([
  z.record(z.unknown()),
  z.array(z.unknown()),
  z.string(),
  z.number(),
]);

const ApiMutationResponseSuccessSchema = z
  .object({
    success: z.literal(true),
    data: ResponseDataSchema.optional(),
  })
  .passthrough();

const ApiQueryResponseSuccessSchema = z
  .object({
    success: z.literal(true),
    data: ResponseDataSchema,
  })
  .passthrough();

export const ApiResponseErrorSchema = z.object({
  success: z.literal(false),
  errors: z.array(
    z.object({
      errorCode: z.string(),
      path: z.union([z.string().nullable(), z.array(z.string())]),
      hints: z.array(z.string()).optional(),
    })
  ),
});

export const ApiQueryResponseSchema = z.union([
  ApiResponseErrorSchema,
  ApiQueryResponseSuccessSchema,
]);

export type ApiQueryResponse = z.infer<typeof ApiQueryResponseSchema>;

export const ApiMutationResponseSchema = z.union([
  ApiResponseErrorSchema,
  ApiMutationResponseSuccessSchema,
]);

export type ApiMutationResponse = z.infer<typeof ApiMutationResponseSchema>;
