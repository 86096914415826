const queryKeys = {
  leaderboardsFilters: () => ["leaderboardsFilters"],
  leaderboards: (range: any, filter: any) => [
    "leaderboardsFilters",
    range,
    filter,
  ],
  profile: (id: number) => ["profile", id],
};

export default queryKeys;
