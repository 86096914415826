import * as z from "zod";
import { ClubSchema, CountrySchema } from "../profile/schema";

export const LeaderboardsItemSchema = z.object({
  id: z.number(),
  user: z.object({
    id: z.number(),
    displayName: z.string(),
    avatar: z.string().nullable(),
  }),
  discipline: z.string(),
  category: z.string(),
  country: CountrySchema,
  club: ClubSchema.nullable(),
  totalScore: z.number(),
  avgScore: z.number(),
  rank: z.number(),
});

export const LeaderboardsMetadataSchema = z.object({
  count: z.number(),
  page: z
    .object({
      from: z.number(),
      to: z.number(),
    })
    .nullable(),
  validUntil: z.string(),
});

export const LeaderboardsSchema = z.object({
  data: z.array(LeaderboardsItemSchema),
  metadata: LeaderboardsMetadataSchema,
});

export type LeaderboardsItem = z.infer<typeof LeaderboardsItemSchema>;
export type LeaderboardsMetadata = z.infer<typeof LeaderboardsMetadataSchema>;
export type Leaderboards = z.infer<typeof LeaderboardsSchema>;
