import React from "react";
import styled from "@emotion/styled";
import breakpoints from "../../helpers/breakpoints";

interface BgDecorationProps {
  top?: boolean;
}

const Root = styled.div<BgDecorationProps>`
  @media (min-width: ${breakpoints.min.md}) {
    background: url(/images/bg-decoration.svg) left center no-repeat;
    background-position: ${(props) => (props.top ? "left top 4.5%" : "unset")};
  }
`;

const BgDecoration: React.FC<BgDecorationProps> = ({ top, children }) => {
  return <Root top={top}>{children}</Root>;
};

export default BgDecoration;
