import { FilterGetParams } from "../../pages/leaderboards";
import { useRouter } from "next/router";

const getObjectWithoutUndefinedValues = (input: Object) => {
  return JSON.parse(JSON.stringify(input));
};

const useChangeFilterInstantly = (query: FilterGetParams) => {
  const router = useRouter();

  const handleSubmitFilterInstantChange = (
    key: string,
    value: string | number
  ) => {
    const queryToSubmit = {
      ...query,
      [key]: value ? value.toString() : undefined,
    };
    router.push(
      { query: getObjectWithoutUndefinedValues(queryToSubmit) },
      undefined,
      { scroll: false }
    );
  };

  return {
    handleSubmitFilterInstantChange,
  };
};

export default useChangeFilterInstantly;
